<!-- 库存管理 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span>仓库名称：</span>
        <Select class="iviewIptWidth250" v-model="queryFrom.warehouse_id" filterable clearable @on-change="changeWare">
          <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>供应商名称：</span>
        <Select filterable clearable v-model="queryFrom.supplier_id" class="iviewIptWidth250">
          <Option v-for="item in supplierList" :value="item.supplier_id" :key="item.supplier_id">{{ item.supplier_name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>厂家名称：</span>
        <Select filterable clearable v-model="queryFrom.factory_id" class="iviewIptWidth250">
          <Option v-for="item in nameList" :value="item.subfactory_id" :key="item.subfactory_id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>产品名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_id" clearable filterable class="iviewIptWidth250" @on-change="productChange">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select placeholder="请选择" v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0" clearable class="iviewIptWidth250" multiple filterable>
          <Option v-for="item in modelList" :value="item.specification_id" :key="item.specification_id" :label="item.model_name + '-' + item.item_number">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>批号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.batch_number"></i-input>
      </FormItem>
      <FormItem>
        <Checkbox v-model="validity" class="margintLeft16">近效期</Checkbox>
        <span class="margintLeft16">有效期：</span>
        <DatePicker :value="indate" :disabled="validity" :editable="false" type="daterange" class="iviewIptWidth250 marginRight60" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime"></DatePicker>
      </FormItem>
      <FormItem>
        <span class="margintLeft16">选择日期：</span>
        <DatePicker :editable="false" :options="limitDate" type="date" class="iviewIptWidth250 marginRight60" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime2"></DatePicker>
      </FormItem>
      <FormItem class="po-create-number " >
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnAdd marginLeft20" @click="upload">导出表格</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
      <div slot="summary" class="summary" v-if="listData.length > 0">
        总数量：<span class="color389">{{ totalNum }}</span> 合计金额：<span class="color389">¥{{ totalMoney }}</span
        >元
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/table'
import editPurchaseOrderVue from '../purchaseManage/editPurchaseOrder.vue'
export default {
  name: 'inventoryManageConsign',
  components: {
    Table,
  },
  computed: {},
  data() {
    return {
      validity: false,
      indate: [],
      // 限制日期
      limitDate: {
        disabledDate(date) {
          return date && date.valueOf() > new Date()
        },
      },
      productList: [],
      modelList: [],
      isLoad: false,
      total: 0,
      totalNum: '',
      totalMoney: '',
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '仓库名称',
          key: 'warehouse_name',
          align: 'center',
        },
        {
          title: '库存数量',
          key: 'inventory_quantity',
          align: 'center',
        },
        {
          title: '金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', param.row.amount ? '¥' + param.row.amount : '0')])
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      listData: [],
      storeList: [],
      queryFrom: {
        supplier_id: '',
        factory_id: '',
        warehouse_id: '',
        product_id: '',
        product_code_array: [],
        page: 1,
        rows: 10,
        batch_number: '',
        start_valid_period: '',
        end_valid_period: '',
        create_time: '',
      },
      supplierList: [],
      nameList: [],
    }
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    this.getListSupList()
    this.pickingPrductModelName()
    this.queryProductList() // 全部产品名称
    this.queryStoreList()
    this.query()
    this.querySKU('') // 全部规格型号
  },
  watch: {
    validity (newValue, oldValue) {
      if (newValue) {
        this.queryFrom.start_valid_period = this.$moment(new Date().getTime()).format('YYYY-MM-DD')
        this.queryFrom.end_valid_period = this.$moment(new Date().getTime()).add(90,'days').format('YYYY-MM-DD')
        this.$set(this, 'indate', [this.$moment(new Date().getTime()).format('YYYY-MM-DD'), this.$moment(new Date().getTime()).add(90,'days').format('YYYY-MM-DD')])
      }
    }
  },
  methods: {
    // 获取厂家下拉列表
    getListSupList() {
      this.$http.get(this.$apiConsign.factoryListBusiness, true).then(res => {
        this.nameList = res.data
      })
    },
    // 供应商下拉
    pickingPrductModelName() {
      this.$http.get(this.$apiConsign.pickingPrductModelName, {}, false).then(res => {
        this.supplierList = res.data
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.start_valid_period = null
        this.queryFrom.end_valid_period = null
      } else {
        this.queryFrom.start_valid_period = e[0]
        this.queryFrom.end_valid_period = e[1]
      }
        this.$set(this, 'indate', e)
    },
    changeTime2(e) {
      if (e == '') {
        this.queryFrom.create_time = null
      } else {
        this.queryFrom.create_time = e
      }
    },
    // 仓库改变
    changeWare(e) {
      this.queryFrom.product_id = ''
      this.productList = []
      this.modelList = []
      if (!e) return
      this.queryProductList()
    },
    // 查询仓库产品列表
    queryProductList() {
      this.$http.get(this.$apiConsign.warehouseManagementProduct, { id: this.queryFrom.warehouse_id }, true).then(res => {
        this.productList = res.data
      })
    },
    // 产品改变
    productChange(e) {
      if (!e) {
        this.querySKU('')
        return
      }
      this.queryFrom.product_code_array = []
      this.modelList = []
      this.querySKU(e)
    },
    // 查询SKU
    querySKU(e) {
      this.$http.get(this.$apiConsign.inventoryCodeLookup, { product_id: e, warehouse_id: this.queryFrom.warehouse_id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 导出
    upload() {
      this.queryFrom.specification_id = this.queryFrom.product_code_array.join(',') + ''
      let obj = {
        warehouse_id: this.queryFrom.warehouse_id,
        product_id: this.queryFrom.product_id,
        specification_id: this.queryFrom.specification_id,
        batch_number: this.queryFrom.batch_number,
        start_valid_period: this.queryFrom.start_valid_period,
        end_valid_period: this.queryFrom.end_valid_period,
        create_time: this.queryFrom.create_time,
        supplier_id: this.queryFrom.supplier_id,
        factory_id: this.queryFrom.factory_id,
      }
      this.$http.downFile(this.$apiConsign.getWarehouseManagementExport, obj, true).then(res => {
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '仓库存导出表.xlsx'
        aLink.click()
      })
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表数据
    queryList() {
      this.queryFrom.specification_id = this.queryFrom.product_code_array.join(',') + ''
      this.$http.get(this.$apiConsign.warehouseManagement, this.queryFrom, true).then(res => {
        this.listData = res.data
        this.total = res.total
        this.totalMoney = res.total_amount
        this.totalNum = res.total_number
        this.isLoad = false
      })
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 分页改变
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    // 详情
    details(row) {
      this.$router.push({
        path: '/inventoryManageDetailConsign',
        query: {
          id: row.id,
          name: row.warehouse_name,
          queryFrom: JSON.stringify(this.queryFrom),
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  .marginLeft20 {
    width: 100px;
  }
  .summary > span:first-child {
    margin-right: 30px;
  }
}
.margintLeft16 {
  margin-left: 16px;
}
</style>
